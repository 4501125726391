<template>
  <div class="grid">
    <div class="col-12 md:col-9 lg:col-9">
      <div class="card">
        <h5>Commande</h5>
        <div v-if="dataviewValue.length ==0" class="card">
          Loading...
      </div>
        <DataView
          v-else
          :value="dataviewValue"
          :layout="layout"
          :paginator="true"
          :rows="9"
          :sortOrder="sortOrder"
          :sortField="sortField"
        >
          <template #header>
            <div class="grid grid-nogutter">
              <div class="col-10 text-left">
                <Dropdown
                  v-model="categorie"
                  :options="sortOptions"
                  optionLabel="nomCategorie"
                  placeholder="Trier par catégorie"
                  @change="onSortChange()"
                />

                <span class="p-input-icon-left mb-2">
                  <i class="pi pi-search" />
                  <InputText v-model="search" style="margin-left: 5px" />
                </span>
              </div>
              <div class="col-6 text-right">
                <!-- <DataViewLayoutOptions v-model="layout" /> -->
              </div>
            </div>
          </template>
          <!-- <template #list="slotProps">
            <div class="col-12">
              <div
                class="
                  flex flex-column
                  md:flex-row
                  align-items-center
                  p-3
                  w-full
                "
              >
                <img
                  :src="
                    'https://gestion.spstechnologie.com/' +
                    slotProps.data.imageProduit
                  "
                  :alt="slotProps.data.nomProduit"
                  class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5"
                />
                <div class="flex-1 text-center md:text-left">
                  <div class="font-bold text-2xl">
                    {{ slotProps.data.nomProduit }}
                  </div>
                  <div class="mb-3">{{ slotProps.data.description }}</div>
                  <Rating
                    :modelValue="slotProps.data.rating"
                    :readonly="true"
                    :cancel="false"
                    class="mb-2"
                  ></Rating>
                  <div class="flex align-items-center">
                    <i class="pi pi-tag mr-2"></i>
                    <span class="font-semibold">{{
                      slotProps.data.category
                    }}</span>
                  </div>
                </div>
                <div
                  class="
                    flex flex-row
                    md:flex-column
                    justify-content-between
                    w-full
                    md:w-auto
                    align-items-center
                    md:align-items-end
                    mt-5
                    md:mt-0
                  "
                >
                  <span
                    class="
                      text-2xl
                      font-semibold
                      mb-2
                      align-self-center
                      md:align-self-end
                    "
                    >${{ slotProps.data.price }}</span
                  >
                  <Button
                    icon="pi pi-shopping-cart"
                    label="Add to Cart"
                    :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"
                    class="mb-2"
                  ></Button>
                  <span :class="'product-badge status-'">{{
                    slotProps.data.inventoryStatus
                  }}</span>
                </div>
              </div>
            </div>
          </template> -->

          <template #grid="slotProps">
            <div class="col-12 md:col-3">
              <div class="card m-3 border-1 surface-border">
                <div class="flex align-items-center justify-content-between">
                  <div class="flex align-items-center">
                    <i class="pi pi-tag mr-2"></i>
                    <span class="font-semibold">{{
                      slotProps.data.nomCategorie
                    }}</span>
                  </div>
                </div>
                <div class="text-center">
                  <img
                    width="100"
                    height="100"
                    :src="
                      url +
                      slotProps.data.imageProduit
                    "
                    :alt="slotProps.data.nomProduit"
                    class="w-9 shadow-2 my-3 mx-0"
                  />
                  <div class="text-2xl font-bold">
                    {{ slotProps.data.nomProduit }}
                  </div>
                  <div class="mb-3">{{ slotProps.data.numSerie }}</div>
                  <span :class="'product-badge status-'"
                    >STOCK: {{ slotProps.data.stock }}</span
                  >
                </div>
                <div class="flex align-items-center justify-content-between">
                  <span class="text-2xl font-semibold"
                    >{{
                      parseFloat(slotProps.data.prixVente).toFixed(2)
                    }}
                    DH</span
                  >
                  <Button
                    icon="pi pi-shopping-cart"
                    @click="addToCart(slotProps.data)"
                  ></Button>
                </div>
              </div>
            </div>
          </template>
        </DataView>
      </div>
    </div>
    <div
      class="col-12 md:col-3 lg:col-3"
      id="containerDiv"
      style="overflow: scroll; height: 700px"
    >
      <div class="card" v-if="cart.length != 0">
        <Divider layout="horizontal" align="center">
          <span class="p-tag">Information client </span>
        </Divider>
        <Dropdown
          style="width: 100%"
          v-model="client"
          :options="clients"
          optionLabel="rsocial"
          placeholder="Choisir un client"
        />
        <small class="p-error" v-if="errors['client']">
          {{ errors["client"] }}
        </small>
        <Dropdown
          style="width: 100%; margin-top: 7px"
          v-model="statut"
          :options="statuts"
          optionLabel="nomStatut"
          placeholder="Statut"
        />
        <small class="p-error" v-if="errors['statut']">
          {{ errors["statut"] }}
        </small>
        <Dropdown
          style="width: 100%; margin-top: 7px"
          v-model="mode"
          :options="modes"
          optionLabel="label"
          placeholder="Mode de paiement"
        />
        <small class="p-error" v-if="errors['mode']">
          {{ errors["mode"] }}
        </small>
        <Dropdown
          style="width: 100%; margin-top: 7px"
          v-model="camion"
          :options="camions"
          optionLabel="full_name"
          placeholder="Choisir un camion"
        />
        <small class="p-error" v-if="errors['camion']">
          {{ errors["camion"] }}
        </small>
        <div style="width: 100%; margin-top: 7px">
          <label for="lastname2">Date d'expedition </label>
          <Calendar v-model="dateEx" :showTime="true" hourFormat="24" />
          <small class="p-error" v-if="errors['dateEx']">
            {{ errors["dateEx"] }}
          </small>
        </div>
        <div style="width: 100%; margin-top: 7px">
          <label for="lastname2">Prix d'expedition </label>
          <InputNumber v-model="prixEX" :allowEmpty="false" :min="0" />
          <small class="p-error" v-if="errors['prixEX']">
            {{ errors["prixEX"] }}
          </small>
        </div>
        <div style="width: 100%; margin-top: 7px">
          <label for="lastname2">Montant payé</label>
          <InputNumber
            v-model="mntP"
            :allowEmpty="false"
            :min="0"
            :max="this.totalttc + this.prixEX"
          />
          <small class="p-error" v-if="errors['mntP']">
            {{ errors["mntP"] }}
          </small>
        </div>
        <div style="width: 100%; margin-top: 7px">
          <label for="lastname2">note de commande </label>
          <Textarea v-model="noteCMD" />
        </div>
        <Divider layout="horizontal" align="center">
          <span class="p-tag">commande</span>
        </Divider>
        <div class="card">
          <div class="cart" v-for="item in cart" :key="item.produit_id">
            <div class="row">
              <div class="col-10" style="display: inline-grid">
                <h5>{{ item.nomProduit }}</h5>
              </div>
              <div class="col-2" style="display: inline-grid; text-align: end">
                <Button
                  @click="deletFromCart(item.produit_id)"
                  icon="pi pi-times"
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-danger
                    p-button-text
                  "
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="p-1" for="firstname2">Quantité</label>

                <InputNumber
                  input="calculateTotal"
                  v-model="item.qtt"
                  showButtons
                  buttonLayout="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  :min="1"
                  :max="item.maxqtt"
                />
              </div>
            </div>

            <div class="row">
              <div
                class="col-6"
                style="display: inline-grid"
                v-if="item.activited"
              >
                <label class="p-1" for="firstname2">Prix</label>
                <InputNumber
                  input="calculateTotal"
                  id="firstname2"
                  :min="0"
                  v-model="item.prix"
                />
                <Button
                  @click="item.activited = false"
                  icon="pi pi-check-circle"
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                  "
                />
              </div>
              <div
                class="col-6"
                style="display: inline-grid"
                v-if="!item.activited"
              >
                <label class="p-1" for="firstname2">Prix</label>
                {{ item.prix }}
                <Button
                  v-if="Permissions.changPrice"
                  @click="item.activited = true"
                  icon="pi pi-cog"
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                  "
                />
              </div>
              <div
                class="col-6"
                style="display: inline-grid"
                v-if="item.activitedr"
              >
                <label class="p-1" for="firstname1">Remis % </label>
                <InputNumber
                  id="firstname1"
                  :min="0"
                  :max="100"
                  v-model="item.remise"
                />
                <Button
                  @click="item.activitedr = false"
                  icon="pi pi-check-circle"
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                  "
                />
              </div>
              <div
                class="col-6"
                style="display: inline-grid"
                v-if="!item.activitedr"
              >
                <label class="p-1" for="firstname1">Remis % </label>
                {{ item.remise }}
                <Button
                  v-if="Permissions.addRemise"
                  @click="item.activitedr = true"
                  icon="pi pi-cog"
                  class="
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-text
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col-6" style="display: inline-grid">Total HT:</div>
            <div class="col-6" style="display: inline-grid; text-align: end">
              {{ totalht }} DH
            </div>
            <div class="col-6" style="display: inline-grid">TVA %:</div>
            <div class="col-6" style="display: inline-grid; text-align: end">
              {{ (totalht * tva) / 100 }} DH
            </div>
            <div class="col-6" style="display: inline-grid">Total TTC:</div>
            <div class="col-6" style="display: inline-grid; text-align: end">
              {{
                parseFloat(parseFloat(totalttc) + parseFloat(prixEX)).toFixed(2)
              }}
              DH
            </div>
          </div>
        </div>
        <div class="card">
          <Button
            label="Ajouter"
            class="p-button-success"
            style="width: 100%"
            @click="checkForm"
          />
          <Button
            @click="cancelOrder"
            label="Anuller"
            class="p-button-danger"
            style="width: 100%; margin-top: 5px"
          />
        </div>
      </div>
      <div class="card" v-else>
        <h5>Le panier est vide</h5>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      url:null,
      Permissions: {
        update: false,
        delete: false,
        add: false,
        show: false,
        changPrice: false,
        addRemise: false,
      },

      dataviewValue: [],
      layout: "grid",
      categorie: null,
      sortOptions: [],
      produits: [],
      clients: [],
      statuts: [],
      modes: [],
      camions: [],
      cart: [],
      errors: [],
      search: null,
      client: null,
      statut: null,
      mode: null,
      camion: null,
      totalht: null,
      totalttc: null,
      tva: null,
      dateEx: null,
      prixEX: 0,
      noteCMD: "",
      mntP: 0,
    };
  },

  mounted() {
    this.url = this.$Gurl;
    this.Permissions.update =
      localStorage.permissionNames.includes("Ajouter Commande");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Ajouter Commande");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter Commande");
    this.Permissions.show =
      localStorage.permissionNames.includes("Ajouter Commande");
    this.Permissions.changPrice =
      localStorage.permissionNames.includes("Modifier prix");
    this.Permissions.addRemise =
      localStorage.permissionNames.includes("Ajouter remis");
    if (this.Permissions.show) {
      this.getProduits();
    } else {
      this.$router.push("/");
    }
    // this.productService
    //   .getProducts()
    //   .then((data) => (this.dataviewValue = data));
  },
  methods: {
    checkForm() {
      let checked = true;
      this.errors = [];
      if (this.client == null) {
        this.errors["client"] = "le champ client est obligatoire.";
        checked = false;
      }
      if (this.mode == null) {
        this.errors["mode"] = "le champ Mode de paiement est obligatoire.";
        checked = false;
      }
      if (this.statut == null) {
        this.errors["statut"] = "le champ statut est obligatoire.";
        checked = false;
      } else if (
        this.statut.nomStatut == "Commande en livraison" &&
        this.camion == null
      ) {
        this.errors["camion"] =
          "Champ camion est obligatoire dans le cas ou la Commande est en livraison.";
        checked = false;
      }
      // if (this.camion == null) {
      //   this.errors["camion"] = "Champ camion est obligatoire.";
      //   checked = false;
      // }
      if (this.dateEx == null) {
        this.errors["dateEx"] = "Champ Date d'expedition est obligatoire.";
        checked = false;
      }
      if (this.prixEX == null) {
        this.errors["prixEX"] = "Le champ Prix d'expedition est obligatoire.";
        checked = false;
      }
      if (checked) {
        this.ajouterCommande();
      } else {
        var myDiv = document.getElementById("containerDiv");
        myDiv.scrollTop = 0;
      }
    },
    ajouterCommande() {
      var reg = 0;
      if (this.mntP == this.prixEX + this.totalttc) {
        reg = 1;
      }
      const cmd = {
        client_id: this.client.id,
        statut_id: this.statut.id,
        date_expidition: moment(this.dateEx).format("YYYY-MM-DD HH:mm:ss"),
        prix_expidition: this.prixEX,
        total_commande: this.totalht,
        total: parseFloat(this.totalttc) + parseFloat(this.prixEX),
        mode_paiements_id: this.mode.id,
        camion_id: null,
        prix_reste:
          parseFloat(this.totalttc) +
          parseFloat(this.prixEX) -
          parseFloat(this.mntP),
        adresse_livraison: this.client.address,
        note_commande: this.noteCMD,
        commande_lines: this.cart,
        prix_paye: this.mntP,
        is_regler: reg,
      };
      if (this.camion != null) {
        cmd.camion_id = this.camion.id;
      }
      console.log(cmd);
      axios
        .post("addCommandeWeb", cmd)
        .then((response) => {
          console.log(response);
          if(response.data.status=="error" && response.data.errorType=="plafont"){
           
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-info'
              },
              buttonsStyling: true
            })

            swalWithBootstrapButtons
              .fire({
                title: 'Erreur de Commande',
                text: response.data.message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirmer',
                cancelButtonText: 'Ok',
                reverseButtons: true
              })
              .then((result) => {
                if (result.isConfirmed) {
                  axios.post("reglercommandes",{"client_id":this.client.id})
                  .then((response) => {
                    console.log(response);
                    if (response.data.status == "Successfully") {
                      this.ajouterCommande();
                    } 
                  })
                  .catch(function (error) {
                    if (error.response.status == 401) {
                      localStorage.removeItem("permissionNames");
                          localStorage.removeItem("user");
                          localStorage.removeItem("token");
                          window.location.replace("/");
                      // self.$router.push({ path: "/login" });
                    // console.log(this.selectedCiasse);
                    }
                  });
                  // Handle the confirmed action here

                  // this.$swal({
                  //   icon: 'success',
                  //   title: 'Deleted!',
                  //   text: 'Your file has been deleted.',
                  //   showConfirmButton: false,
                  //   timer: 2500
                  // });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // Handle the cancellation action here
                  this.cancelOrder();
                  this.$swal({
                    icon: 'error',
                    title: 'Confirmation',
                    text: "Merci d'avoir confirmé. Votre demande a été enregistrée. Veuillez contacter l'administrateur",
                    showConfirmButton: false,
                    timer: 3000
                  });
                }
              })
          }else if (response.data.status=="error" && response.data.errorType=="camion"){
            this.$swal({
              title: response.data.message,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            });
          }else{
          this.getProduits();
          this.cancelOrder();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          }
          
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    cancelOrder() {
      this.cart = [];
      this.client = null;
      this.statut = null;
      this.mode = null;
      this.camion = null;
      this.totalht = null;
      this.totalttc = null;
      this.dateEx = null;
      this.prixEX = 0;
      this.noteCMD = "";
    },

    calculateTotal() {
      if (this.cart) {
        var remise = 0;
        var totalht = 0;
        this.totalht = 0;
        this.totalttc = 0;
        this.remise = 0;
        this.cart.forEach((element) => {
          remise = 0;
          totalht = 0;
          remise = element.remise / 100;
          remise = element.qtt * element.prix * remise;
          totalht = element.qtt * element.prix - remise;
          this.totalht = this.totalht + totalht;
        });
      }
      this.totalttc = this.totalht * (1 + this.tva / 100);
      this.totalht = parseFloat(this.totalht).toFixed(2);
      this.totalttc = parseFloat(this.totalttc).toFixed(2);
    },
    deletFromCart(id) {
      var value = this.cart.findIndex((elem) => elem.produit_id === id);
      this.cart.splice(value, 1);
    },
    addToCart(produit) {
      if (this.cart.length == 0) {
        this.cart.push({
          maxqtt: produit.stock,
          produit_id: produit.id,
          nomProduit: produit.nomProduit,
          qtt: 1,
          prix: produit.prixVente,
          remise: 0,
          activited: false,
          activitedr: false,
        });
        this.$toast.add({
          severity: "success",
          summary: "Message de succès",
          detail: "le produit a bien été ajouté",
          life: 3000,
        });
      } else {
        this.cart.push({
            maxqtt: produit.stock,
            produit_id: produit.id,
            nomProduit: produit.nomProduit,
            qtt: 1,
            prix: produit.prixVente,
            remise: 0,
            activited: false,
            activitedr: false,
          });
          this.$toast.add({
            severity: "success",
            summary: "Message de succès",
            detail: "le produit a bien été ajouté",
            life: 3000,
          });
       
      }
    },
    getProduits() {
      axios
        .get("formAddCommande")
        .then((response) => {
          console.log(response);
          if (response.data.status == "error") {
            this.$swal("Erreur ", response.data.message);
          } else {
            this.dataviewValue = response.data.produit;
            this.produits = response.data.produit;
            this.sortOptions = response.data.categorie;
            this.sortOptions.unshift({ id: 0, nomCategorie: "tout" });
            this.modes = response.data.modepaiement;
            this.statuts = response.data.statut;
            this.camions = response.data.camion;
            this.clients = response.data.client;
            this.tva = response.data.tva;
            this.search = "";
            this.categorie = null;
            this.loading1 = false;
          }
        })
        .catch(function (error) {
            console.log(error);
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    onSortChange() {
      this.search = "";
      if (this.categorie.id == 0) {
        this.dataviewValue = this.produits;
      } else {
        this.dataviewValue = this.produits.filter((obj) => {
          return obj.categorie_id === this.categorie.id;
        });
      }
    },
    searchIt() {
      this.dataviewValue = this.dataviewValue.filter((obj) => {
        return (
          obj.nomProduit.includes(this.search) ||
          obj.numSerie.includes(this.search)
        );
      });
    },
  },
  watch: {
    // whenever question changes, this function will run
    search() {
      if (this.search != "") {
        this.searchIt();
      } else {
        if (this.categorie == null) {
          this.dataviewValue = this.produits;
        } else {
          this.onSortChange();
        }
      }
    },

    cart: {
      handler: function () {
        this.calculateTotal();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>

<style>
.p-inputtext {
  width: 100%;
}
</style>